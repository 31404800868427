import React, { useContext, useState } from 'react';
import { Card } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { CartContext } from './CartContext';
import { UserContext } from './UserContext';
import './styles/WorkCard.css'; // Import the CSS file
import WorkOptionsModal from './WorkOptionsModal';

const WorkCard = ({ work, worksGroup=[], handleDelete, openEditModal }) => {
  const { cart} = useContext(CartContext);
  const { user } = useContext(UserContext);
  const [optionsModalShow, setOptionsModalShow] = useState(false);

  // Calculate total items in the worksGroup that are in the cart

  const groupQuantity = worksGroup.reduce((total, work) => {
    const cartItem = cart[work.id];
    // Only add quantity if cartItem exists and has a valid quantity
    return total + (cartItem && cartItem.quantity ? cartItem.quantity : 0);
  }, 0);

  return (
    <>
    <Card className="work-card">
      <div className="card-content">
        <div className="work-details">
          <h6>{work.group}</h6>
          <p>Starts at <strong>₹{work.price}</strong></p>
          <ul>
            <li>Includes {work.description}</li> 
          </ul>
        </div>
        <div className="image-and-actions">
          <img src="/a.jpg" alt={work.name} className="work-image" />

          {user && user.role === 'ADMIN' ? (
            <>
              <Button variant="secondary" onClick={() => openEditModal(work)} className="admin-button">Edit</Button>
              <Button variant="danger" onClick={() => handleDelete(work.id)} className="admin-button">Delete</Button>
            </>
          ) : (


            groupQuantity === 0 ? (
              <Button
                variant="outline-primary"
                onClick={()=>setOptionsModalShow(true)} 
                className="custom-outline-button"
              >
                Add
              </Button>
            ) : (
              <div className="quantity-controls">
                <button
                  className="btn btn-outline"
                  onClick={()=>setOptionsModalShow(true)} 
                >
                  -
                </button>
                <span className="quantity">{groupQuantity}</span>
                <button
                  className="btn btn-outline"
                  onClick={()=>setOptionsModalShow(true)} 
                >
                  +
                </button>
              </div>
            )
            
          )}
        </div>
      </div>
    </Card>

    
    <WorkOptionsModal worksGroup={worksGroup}  show={optionsModalShow} handleClose={()=>setOptionsModalShow(false)}/>
    </>
  );
};

export default WorkCard;
