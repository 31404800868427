import React, { useState,useEffect, useContext } from 'react';
import { UserContext } from './UserContext';
import { Button, Container, Form, Card, Row, Col} from 'react-bootstrap';
import './styles/Profile.css'

const Profile = () => {
  const { user, updateUser } = useContext(UserContext);
  const [editing, setEditing] = useState(false);

   // Initialize local form data
   const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phno: '',
  });

  useEffect(() => {
    if (user) {
      setFormData({
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        email: user.email || '',
        phno: user.phno || '',
      });
    }
  }, [user]);
  
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleEdit = () => {
    setEditing(true);
  };

  const handleSave = async () => {
    // Update context state
    console.log(user.id, user.firstName)
    await updateUser(user.id,formData);
    setEditing(false);
  };

  const handleCancel = () => {
    setEditing(false);
    // Optionally reset formData to user context values if needed
    setFormData({
      firstName: user.firstName || '',
      lastName: user.lastName || '',
      email: user.email || '',
      phno: user.phno || '',
    });
  };
  if (!user) {
    return <div className="container py-5"><p>Loading profile...</p></div>;
  }

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col xs={12} md={8} lg={6}>
          <h1>Profile</h1>
          <Card className="my-3 shadow-sm">
            
              <Form>
         
                <Form.Group className="border-bottom pb-2">
                  <Form.Label className="fw-bold">First Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    disabled={!editing}
                    plaintext={!editing}
                  />
                </Form.Group>
         
       

                <Form.Group className="border-bottom pb-2">
                  <Form.Label className="fw-bold">Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    disabled={!editing}
                    plaintext={!editing}
                  />
                </Form.Group>
           
               
                <Form.Group className="border-bottom pb-2">
                  <Form.Label className="fw-bold">Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    disabled={!editing}
                    plaintext={!editing}
                  />
                </Form.Group>

                <Form.Group className="border-bottom pb-2">
                  <Form.Label className="fw-bold">Phone Number</Form.Label>
                  <Form.Control
                    type="text"
                    name="phno"
                    value={formData.phno}
                    onChange={handleChange}
                    disabled={!editing}
                    plaintext={!editing}
                  />
                </Form.Group>

                <div className="d-flex justify-content-end mt-3">
                  {editing ? (
                    <>
                      <Button variant="secondary" onClick={handleCancel} className="me-2">
                        Cancel
                      </Button>
                      <Button variant="primary" onClick={handleSave}>
                        Save
                      </Button>
                    </>
                  ) : (
                    <Button variant="primary" onClick={handleEdit} className='custom-btn'>
                      Edit
                    </Button>
                  )}
                </div>
              </Form>
            
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
export default Profile;