import React, { useState, useContext } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import './styles/LoginModal.css';
import { UserContext } from './UserContext';

const LoginModal = ({ show, handleClose }) => {
  const [phno, setPhno] = useState('');
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [step, setStep] = useState(1);
  const [errorMessages, setErrorMessages] = useState({}); // Track error messages

  const { signup, login } = useContext(UserContext);

  const checkUserExistence = async () => {
    try {
      const response = await fetch('https://api.weclear.in/api1/auth/check-user', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phno }),
        credentials: 'include',
      });

      if (response.ok) {
        const userExists = await response.json();
        return userExists;
      } else {
        throw new Error('Failed to check user existence');
      }
    } catch (error) {
      console.error(error);
      setErrorMessages({ general: 'An error occurred. Please try again.' });
      return false;
    }
  };

  // Validation functions
  const validatePhoneNumber = (number) => {
    const phoneRegex = /^[0-9]{10}$/; // Regex for 10 digit phone number
    return phoneRegex.test(number);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    const minLength = 6;
    const hasNumber = /\d/; // Check for at least one number
    return password.length > minLength && hasNumber.test(password);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessages({}); // Reset errors

    if (step === 1) {
      if (!validatePhoneNumber(phno)) {
        setErrorMessages((prev) => ({ ...prev, phno: 'Invalid phone number' }));
        return;
      }
      const res = await checkUserExistence();
      if (res) {
        setStep(2);
      } else {
        setStep(3);
      }
    } else if (step === 2) { // Login
      const success = await login(phno, password);
      if (!success) {
        setErrorMessages((prev) => ({ ...prev, general: 'Invalid credentials' }));
      } else {
        handleClose();
      }
    } else if (step === 3) { // Signup
      const errors = {};
      if (!validateEmail(email)) {
        errors.email = 'Invalid email format.';
      }
      if (!validatePassword(password)) {
        errors.password = 'Password must be at least 7 characters long and contain at least one number.';
      }
      if (Object.keys(errors).length > 0) {
        setErrorMessages(errors);
        return;
      }

      const success = await signup(firstName, lastName, email, phno, password);
      if (!success) {
        setErrorMessages((prev) => ({ ...prev, general: 'Signup failed' }));
      } else {
        handleClose();
      }
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      dialogClassName="full-height-right-modal p-0 m-0"
      style={{
        position: 'fixed',
        right: 0,       // Align to the right
        top: 0,         // Start at the top
        left: 'auto',   // Prevents alignment to the left
        height: '100vh', 
        width: '30vw',  // Adjust width as needed
        maxWidth: '400px',
        margin: 0,
        transform: 'none', // Prevents centering transform
        zIndex: 1050     // Ensures it appears above other elements
      }}
    >
      <Modal.Header closeButton className="border-0">
        <Modal.Title className="fs-4 p-4 ml-4">
          {step === 1 ? 'Login/ Signup' : step === 2 ? 'Login' : 'New User Signup'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit} className="p-3">
          {errorMessages.general && ( 
            <div className="error-message text-danger mb-3 text-center">
              {errorMessages.general}
            </div>
          )}

          {step === 1 && (
            <Form.Group className="mb-3">
              <Form.FloatingLabel label="Phone Number" controlId="phno">
                <Form.Control
                  type="text"
                  value={phno}
                  maxLength={10}
                  onChange={(e) => setPhno(e.target.value)}
                  required
                />
                {errorMessages.phno && (
                  <div className="invalid-feedback">{errorMessages.phno}</div>
                )}
              </Form.FloatingLabel>
            </Form.Group>
          )}

          {step === 2 && (
            <Form.Group controlId="pwd" className="mb-3">
              <Form.FloatingLabel label="Password">
                <Form.Control
                  type="password"
                  placeholder="Enter password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </Form.FloatingLabel>
            </Form.Group>
          )}

          {step === 3 && (
            <>
              <Form.Group className="mb-3">
                <Form.FloatingLabel label="First Name" controlId="fname">
                  <Form.Control
                    type="text"
                    placeholder="Enter First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                </Form.FloatingLabel>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.FloatingLabel label="Last Name" controlId="lname">
                  <Form.Control
                    type="text"
                    placeholder="Enter Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                  />
                </Form.FloatingLabel>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.FloatingLabel label="Email" controlId="email">
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  {errorMessages.email && (
                    <div className="text-danger">{errorMessages.email}</div>
                  )}
                </Form.FloatingLabel>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.FloatingLabel label="Password" controlId="pwd">
                  <Form.Control
                    type="password"
                    placeholder="Enter password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  {errorMessages.password && (
                    <div className="text-danger">{errorMessages.password}</div>
                  )}
                </Form.FloatingLabel>
              </Form.Group>
            </>
          )}
          <Button
            variant="primary"
            type="submit"
            className="w-100 mt-3  custom-btn">
            Continue
          </Button>
        </Form>
        <p className="text-center mt-3" style={{ fontSize: '0.8rem' }}>
            By clicking on Continue, I accept the <span style={{ color: 'orange' }}>Terms & Conditions & Privacy Policy</span>
          </p>
      </Modal.Body>
    </Modal>
  );
};

export default LoginModal;
