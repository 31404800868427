import React, { useState } from 'react';
import { Form, Row, Col, Button, ListGroup, Card, Dropdown, DropdownButton, FloatingLabel } from 'react-bootstrap';
import './styles/VendorForm.css';

const ServiceForm = () => {
 
    const [selectedServices, setSelectedServices] = useState([]);
  const [formData, setFormData] = useState({
    city: '',
    companyName: '',
    aadhaarNo: '',
    gstNo: ''
  });
  const [contacts, setContacts] = useState([{ name: '', mobile: '', email: '' }]);
  const [addresses, setAddresses] = useState([{ pinCode: '', street: '', houseNo: '', apartment: '', landmark: '', block: '', district: '', state: '', country: '' }]);
  const [pinCodes, setPinCodes] = useState([{ pinCode: '', area: '' }]);

  const services = [
    'Refrigerator Repair',
    'Washing Machine Repair',
    'Air Conditioning Repair / Service',
    'TV Installation / Repair',
    'Microwave Oven Repair',
    'Water Purifier Repair',
    'Chimney Repair',
    'Hob Repair',
    'Gas Stove Repair',
    'Cooking Range Repair',
    'Dishwasher Repair',
    'Geyser Repair',
    'Gas Geyser',
    'Solar Repair',
    'Inverter / UPS Repair',
    'Water Level Controller',
    'Water Motor Repair',
    'Laptop / Desktop Repair',
    'CCTV Installation / Repair',
    'Plumber',
    'Electrician',
    'Carpenter',
    'Full House Deep Cleaning',
    'Sofa Cleaning',
    'Office Cleaning',
    'Kitchen Cleaning',
    'Bathroom Cleaning',
    'Carpet Cleaning',
    'Mattress Cleaning',
    'Sump & Tank Cleaning',
    'Exterior Cleaning',
    'House Painting',
    'Water Proofing',
    'Interior Design',
    'House Rennovation',
    'Packers and Movers',
    'Fabrication',
    'Bike Repair',
    'Car Repair',
    'Window Cleaning',
    'Car Service and Cleaning'
  ];

  const handleServiceChange = (service) => {
    setSelectedServices((prev) =>
      prev.includes(service)
        ? prev.filter((s) => s !== service)
        : [...prev, service]
    );
  };

  const handleInputChange = (e, index, field, type) => {
    const { value } = e.target;
    if (type === 'contacts') {
      const updatedContacts = [...contacts];
      updatedContacts[index][field] = value;
      setContacts(updatedContacts);
    } else if (type === 'addresses') {
      const updatedAddresses = [...addresses];
      updatedAddresses[index][field] = value;
      setAddresses(updatedAddresses);
    } else if (type === 'pinCodes') {
      const updatedPinCodes = [...pinCodes];
      updatedPinCodes[index][field] = value;
      setPinCodes(updatedPinCodes);
    } else {
      setFormData({ ...formData, [field]: value });
    }
  };

  const handleAddField = (type) => {
    if (type === 'contacts') {
      setContacts([...contacts, { name: '', mobile: '', email: '' }]);
    } else if (type === 'addresses') {
      setAddresses([...addresses, { pinCode: '', street: '', houseNo: '', apartment: '', landmark: '', block: '', district: '', state: '', country: '' }]);
    } else if (type === 'pinCodes') {
      setPinCodes([...pinCodes, { pinCode: '', area: '' }]);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Selected Services:', selectedServices);
    console.log('Form Data:', formData);
    console.log('Contacts:', contacts);
    console.log('Addresses:', addresses);
    console.log('Pin Codes:', pinCodes);
  };

  return (
    <Form onSubmit={handleSubmit} className="service-form p-4">

      {/* Choose Services Section */}

      <Form.Group className="mb-4">
    <Form.Label><strong>Choose Your Services *</strong></Form.Label>
    
    <Row>
    


        <Col sm={6}>
            <ListGroup className="border p-3" style={{ maxHeight: '250px', overflowY: 'auto' }}>
                {services.map((service, index) => (
                    <ListGroup.Item key={index} className="border-0 p-1">
                        <Form.Check
                            type="checkbox"
                            label={service}
                            checked={selectedServices.includes(service)}
                            onChange={() => handleServiceChange(service)}
                            className="text-muted"
                        />
                    </ListGroup.Item>
                ))}
            </ListGroup>
        </Col>

        {selectedServices.length!==0 &&(
        <Col sm={6} className="text-start">
            <div><strong>Selected services</strong></div>
            <div className="d-flex flex-wrap mt-2">
                {selectedServices.map((item, index) => (
                    <span key={index} className="badge mx-1 mb-2 p-2">
                        {item}
                    </span>
                ))}
            </div>
        </Col>
        )}
    </Row>
</Form.Group>


{/* City Selection */}
<Card className="mb-4 p-3">
                <Form.Group className='w-25'>
                    <Form.Label>Select Your City *</Form.Label>
                    <Form.Select>
                        <option>Select a City</option>
                        <option>Hyderabad</option>
                        <option>Benguluru</option>
                    </Form.Select>
                </Form.Group>
            </Card>



      {/* Vendor Information Section */}
      <Card className="mb-4 p-3">
        <h6 className="text-muted mt-4 mb-3"><strong>Vendor Information</strong></h6>
        <Row className="mb-3">
            <Col md={6}>
      
                <FloatingLabel label="Company Name" controlId="floatingInput">
                <Form.Control
                type="text"
                name="companyName"
                value={formData.companyName}
                onChange={(e) => handleInputChange(e, null, 'companyName')}
                
                className="text-muted"
                placeholder='Company Name'
                required
                />
                </FloatingLabel>
        
            </Col>
            <Col md={3}>
                <FloatingLabel label="Aadhar No">
                <Form.Control
                type="text"
                name="aadhaarNo"
                value={formData.aadhaarNo}
                onChange={(e) => handleInputChange(e, null, 'aadhaarNo')}
                required
                className="text-muted"
                placeholder='xxxx xxxx 1234'
                />
                </FloatingLabel>
            </Col>
            <Col md={3}>
            <FloatingLabel label="GST No">
                <Form.Control
                type="text"
                name="gstNo"
                value={formData.gstNo}
                onChange={(e) => handleInputChange(e, null, 'gstNo')}
                className="text-muted"
                placeholder='1234'
                />
            </FloatingLabel>
            </Col>
        </Row>
        </Card>

      {/* Contacts Section */}
      <Card className="mb-4 p-3">
        <h6 className="text-muted mt-4 mb-3"><strong>Contacts *</strong></h6>
        {contacts.map((contact, index) => (
        <Row className="mb-3" key={index}>
            <Col md={4}>
                <Form.Group>
                <Form.Label>Name *</Form.Label>
                <Form.Control
                    type="text"
                    value={contact.name}
                    onChange={(e) => handleInputChange(e, index, 'name', 'contacts')}
                    required
                />
                </Form.Group>
            </Col>
            <Col md={4}>
                <Form.Group>
                <Form.Label>Mobile *</Form.Label>
                <Form.Control
                    type="text"
                    value={contact.mobile}
                    onChange={(e) => handleInputChange(e, index, 'mobile', 'contacts')}
                    required
                />
                </Form.Group>
            </Col>
            <Col md={4}>
                <Form.Group>
                <Form.Label>Email *</Form.Label>
                <Form.Control
                    type="email"
                    value={contact.email}
                    onChange={(e) => handleInputChange(e, index, 'email', 'contacts')}
                    required
                />
                </Form.Group>
            </Col>
        </Row>
      ))}

      <Button variant="link" onClick={() => handleAddField('contacts')}>+ Add Contact</Button>
</Card>


<Card className="mb-4 p-3">
      {/* Address Section */}
      <h6 className="text-muted mt-4 mb-3"><strong>Address *</strong></h6>
      {addresses.map((address, index) => (
        <Row className="mb-3" key={index}>
          <Col md={3}>
            <Form.Group>
              <Form.Label>Pin Code *</Form.Label>
              <Form.Control
                type="text"
                value={address.pinCode}
                onChange={(e) => handleInputChange(e, index, 'pinCode', 'addresses')}
                required
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label>Street/Location *</Form.Label>
              <Form.Control
                type="text"
                value={address.street}
                onChange={(e) => handleInputChange(e, index, 'street', 'addresses')}
                required
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label>House No</Form.Label>
              <Form.Control
                type="text"
                value={address.houseNo}
                onChange={(e) => handleInputChange(e, index, 'houseNo', 'addresses')}
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label>Block/Town/City</Form.Label>
              <Form.Control
                type="text"
                value={address.block}
                onChange={(e) => handleInputChange(e, index, 'block', 'addresses')}
              />
            </Form.Group>
          </Col>
        </Row>
      ))}
      <Button variant="link" onClick={() => handleAddField('addresses')}>+ Add Address</Button>
      </Card>

      <Card className="mb-4 p-3">

      {/* Pin Codes Section */}
      <h6 className="text-muted mt-4 mb-3"><strong>Areas you serve</strong></h6>
      {pinCodes.map((pinCode, index) => (
        <Row className="mb-3" key={index}>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Areas you serve *</Form.Label>
              <Form.Control
                type="textarea"
                value={pinCode.area}
                onChange={(e) => handleInputChange(e, index, 'area', 'pinCodes')}
                required
              />
            </Form.Group>
          </Col>
        </Row>
      ))}
      </Card>



       {/* Documents section */}
<Card className="mb-4 p-4 shadow-sm border-0">
  <h6 className="text-muted mt-3 mb-4 font-weight-bold">Upload Documents *</h6>
  
  <Row className="g-4">
    <Col md={3}>
      <Form.Group controlId="photoUpload">
        <Form.Label className="fw-semibold">Photo</Form.Label>
        <Form.Control
          type="file"
          className="form-control-file border rounded p-2"
          //value={address.pinCode}
          //onChange={(e) => handleInputChange(e, index, 'pinCode', 'addresses')}
          //required
        />
      </Form.Group>
    </Col>
    <Col md={3}>
      <Form.Group controlId="aadhaarFront">
        <Form.Label className="fw-semibold">Aadhaar Card Frontside</Form.Label>
        <Form.Control
          type="file"
          className="form-control-file border rounded p-2"
          //value={address.street}
          //onChange={(e) => handleInputChange(e, index, 'street', 'addresses')}
          //required
        />
      </Form.Group>
    </Col>
    <Col md={3}>
      <Form.Group controlId="aadhaarBack">
        <Form.Label className="fw-semibold">Aadhaar Card Backside</Form.Label>
        <Form.Control
          type="file"
          className="form-control-file border rounded p-2"
          //value={address.houseNo}
          //onChange={(e) => handleInputChange(e, index, 'houseNo', 'addresses')}
        />
      </Form.Group>
    </Col>
    <Col md={3}>
      <Form.Group controlId="gstCertificate">
        <Form.Label className="fw-semibold">GST Certificate (if any)</Form.Label>
        <Form.Control
          type="file"
          className="form-control-file border rounded p-2"
          //value={}
          //onChange={(e) => handleInputChange(e, index, 'block', 'addresses')}
        />
      </Form.Group>
    </Col>
  </Row>
</Card>

      {/* Submit Button */}
      <Button variant="primary" type="submit" className="mt-4">Submit</Button>
    </Form>
  );
};

export default ServiceForm;
