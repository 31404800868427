import React, { useContext, useState } from 'react';
import { CartContext } from './CartContext';
import './styles/WorkCard.css';
import { Modal, Button, Card } from 'react-bootstrap';

const WorkOptionsModal = ({ worksGroup, show, handleClose }) => {
  const { cart, updateQuantity, removeFromCart } = useContext(CartContext);


  const [quantities, setQuantities] = useState(
    worksGroup.reduce((acc, work) => {
        acc[work.id] = cart[work.id]?.quantity || 0; // Ensure this returns a number
        return acc;
    }, {})
);


  const handleIncrement = (work) => {
    const newQuantity = (quantities[work.id] || 0) + 1;
    setQuantities((prev) => ({ ...prev, [work.id]: newQuantity }));
    updateQuantity(work.id, work.name, work.price, newQuantity);
  };

  const handleDecrement = (work) => {
    if (quantities[work.id] > 1) {
      const newQuantity = quantities[work.id] - 1;
      setQuantities((prev) => ({ ...prev, [work.id]: newQuantity }));
      updateQuantity(work.id, work.name, work.price, newQuantity);
    } else if (quantities[work.id] === 1) {
      removeFromCart(work.id);
      setQuantities((prev) => ({ ...prev, [work.id]: 0 }));
    }
  };

  const handleAdd = (work) => {
    setQuantities((prev) => ({ ...prev, [work.id]: 1 }));
    updateQuantity(work.id, work.name, work.price, 1);
  };

              
            


  

  return (
    <Modal show={show} onHide={handleClose} centered>
    <Modal.Header closeButton >
        <Modal.Title className='modal-title'>{worksGroup[0]?.group}</Modal.Title>
    </Modal.Header>

    <Modal.Body>
  {worksGroup.map((work) => (
    <Card key={work.id} className="work-card">
      <div className="card-content">
        <div className="work-details">
          <h6>{work.name}</h6>
          <p><strong>₹{work.price}</strong></p>
          <p>{work.duration}</p>
          <ul>
            <li>Includes {work.description}</li>
          </ul>
        </div>
        <div className="image-and-actions">
          <img src="/a.jpg" alt={work.group} className="work-image" />
          {!(work.id in cart) || quantities[work.id] === 0 ? (
            <Button
              variant="outline-primary"
              onClick={() => handleAdd(work)}
              className="custom-outline-button"
            >
              Add
            </Button>
          ) : (
            <div className="quantity-controls">
              <button className="btn btn-outline" onClick={() => handleDecrement(work)}>
                -
              </button>
              <span className="quantity">{quantities[work.id]}</span> {/* Ensure this is not an object */}
              <button className="btn btn-outline" onClick={() => handleIncrement(work)}>
                +
              </button>
            </div>
          )}
        </div>
      </div>
    </Card>
  ))}
</Modal.Body>



  
    
    <Modal.Footer>
      <Button  onClick={handleClose} className='btn custom-btn'>
        Done
      </Button>
    </Modal.Footer>
  </Modal>
  );
};

export default WorkOptionsModal;
