import React, { useState, useEffect, useContext } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { CategoryContext } from './CategoryContext';

const WorkModal = ({ show, onHide, sub_category, selectedService, handleSubmit }) => {

    const {category} = useContext(CategoryContext);

    const [serviceData, setServiceData] = useState({
        name: '',
        group:'',
        description: '',
        price: '',
        duration: '',
        showQuantity: true
    });

    useEffect(() => {
        if (selectedService) {
            // If editing, populate fields with selectedService data
            setServiceData({
                name: selectedService.name || '',
                group: selectedService.group || '',
                description: selectedService.description || '',
                price: selectedService.price || '',
                duration: selectedService.duration || '',
                showQuantity: selectedService.showQuantity || true
            });
        } else {
            // If adding, ensure the form is cleared
            setServiceData({
                name: '',
                description: '',
                price: '',
                duration: '',
                showQuantity: true
            });
        }
    }, [selectedService]);

    const handleChange = (e) => {
        setServiceData({
            ...serviceData,
            [e.target.name]: e.target.value
        });
    };

    const onSubmit = () => {
        const dataToSubmit = {
            ...serviceData,
            workCategory: category,
            workSubCategory: sub_category
        };
    
        console.log("going data", dataToSubmit);
    
        handleSubmit(dataToSubmit); // Pass the updated data
        onHide(); // Close modal after submission
    };

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{selectedService ? 'Edit Service' : 'Add New Service'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter service name"
                            name="name"
                            value={serviceData.name}
                            onChange={handleChange}
                        />
                    </Form.Group>

                    <Form.Group controlId="group">
                        <Form.Label>Group</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter group"
                            name="group"
                            value={serviceData.group}
                            onChange={handleChange}
                        />
                    </Form.Group>

                    <Form.Group controlId="description">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter description"
                            name="description"
                            value={serviceData.description}
                            onChange={handleChange}
                        />
                    </Form.Group>

                    <Form.Group controlId="price">
                        <Form.Label>Price</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Enter price"
                            name="price"
                            value={serviceData.price}
                            onChange={handleChange}
                        />
                    </Form.Group>

                    <Form.Group controlId="duration">
                        <Form.Label>Duration</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter duration"
                            name="duration"
                            value={serviceData.duration}
                            onChange={handleChange}
                        />
                    </Form.Group>

                    <Form.Group controlId="image">
                        <Form.Label>Image</Form.Label>
                        <Form.Control
                            type="file"
                            placeholder="Upload image"
                            name="image"
                            value={serviceData.image}
                            onChange={handleChange}
                        />
                    </Form.Group>

                    <Form.Group controlId="showQuantity">
                        <Form.Label>Should we show quantity to user?</Form.Label>
                        <div className="d-flex flex-column">
                            <Form.Check
                                type="radio"
                                label="true"
                                name="showQuantity"
                                value="true"
                                checked={serviceData.showQuantity === true}
                                onChange={handleChange}
                            />
                            <Form.Check
                                type="radio"
                                label="Not Available"
                                name="showQuantity"
                                value="false"
                                checked={serviceData.showQuantity === false}
                                onChange={handleChange}
                            />
                        </div>
                    </Form.Group>

                </Form>
            </Modal.Body>
            <Modal.Footer>
                
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
                <Button variant="primary" onClick={onSubmit}>
                    {selectedService ? 'Save Changes' : 'Add Service'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default WorkModal;