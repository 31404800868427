import React, { createContext, useState, useEffect } from 'react';

export const CategoryContext = createContext();

export const CategoryProvider = ({ children }) => {
    const [category, setCategory] = useState(null);

    useEffect(() => {
        const storedCategory = localStorage.getItem('category');
        if (storedCategory) {
            setCategory(JSON.parse(storedCategory));
        }
    }, []);

    useEffect(() => {
        if (category) {
            localStorage.setItem('category', JSON.stringify(category));
        } 
    }, [category]);

    const handleCategorySelect = async (category) => {
        setCategory(String(category).toUpperCase());
    };


    return (
        <CategoryContext.Provider value={{ category, handleCategorySelect}}>
            {children}
        </CategoryContext.Provider>
    );
};