import React, { useState, useContext } from "react";
import { Modal, Button, Col, Row } from "react-bootstrap";
import { CategoryContext } from "./CategoryContext";
import { FaHome, FaBuilding } from "react-icons/fa";
import "./styles/CategoryModal.css";
import { CartContext } from "./CartContext";

const CategoryModal = ({ show, handleClose }) => {
  const { handleCategorySelect } = useContext(CategoryContext);
  const [selectedCategory, setSelectedCategory] = useState("");
  const {emptyCart} = useContext(CartContext);

  const handleSubmit = (e) => {
    emptyCart();
    e.preventDefault();
    handleCategorySelect(selectedCategory);
    handleClose();
  };

  return (
    <Modal
      show={show} 
      dialogClassName="right-modal modal-lg "
      className="custom-modal"
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header className="border-0 d-flex justify-content-center">
        <Modal.Title
          className="fs-4 tagline"
    
        >
          We convert Things to Look More Beautiful!
        </Modal.Title>
      </Modal.Header>
      <hr style={{ border: "1px solid #003050", margin: "0px 0" }} />
      <Modal.Body className="category-selection d-flex flex-column align-items-center">
      
          {/*<Col md={4} className="d-flex flex-row align-items-center">
            <h4>Select the type of services</h4>
          </Col>

  <Col md={8} className="d-flex justify-content-center">*/}
  <h5>Select the type of services</h5>
  <form onSubmit={handleSubmit} className="w-100">
    <div className="d-flex justify-content-around w-100 mt-3">
      {/* Residential Option */}
      <label className="text-center category-option">
        <input
          type="radio"
          name="category"
          value="residential"
          checked={selectedCategory === "residential"}
          onChange={(e) => setSelectedCategory(e.target.value)}
          style={{ display: "none" }}
        />
        <div
          className={`icon-container ${
            selectedCategory === "residential" ? "selected" : ""
          }`}
        >
          <FaHome size={70} className="icon" />
          <span>Residential</span>
        </div>
      </label>
      {/* Commercial Option */}
      <label className="text-center category-option">
        <input
          type="radio"
          name="category"
          value="commercial"
          checked={selectedCategory === "commercial"}
          onChange={(e) => setSelectedCategory(e.target.value)}
          style={{ display: "none" }}
        />
        <div
          className={`icon-container ${
            selectedCategory === "commercial" ? "selected" : ""
          }`}
        >
          <FaBuilding size={70} className="icon" />
          <span>Commercial</span>
        </div>
      </label>
    </div>
    <Button
      type="submit"
      className="w-100 mt-4  custom-btn"
      disabled={!selectedCategory}
    >
      OK
    </Button>
  </form>



      </Modal.Body>
    </Modal>
  );
};

export default CategoryModal;
