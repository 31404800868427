import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import './styles/Footer.css';
import { FaFacebookSquare, FaInstagram, FaTwitter } from 'react-icons/fa';

function Footer() {
    return (
        <footer className="footer">
            <Container>
                <Row>
                    <Col xs={12} sm={6} md={2}>
                        <h5>Company</h5>
                        <ul className="nav flex-column">
                            <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Home</a></li>
                            <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Features</a></li>
                            <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Pricing</a></li>
                            <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">FAQs</a></li>
                            <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">About</a></li>
                        </ul>
                    </Col>

                    <Col xs={12} sm={6} md={2}>
                        <h5>For Customers</h5>
                        <ul className="nav flex-column">
                            <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Home</a></li>
                            <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Features</a></li>
                            <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Pricing</a></li>
                            <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">FAQs</a></li>
                            <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">About</a></li>
                        </ul>
                    </Col>

                    <Col xs={12} sm={6} md={2}>
                        <h5>For Partners</h5>
                        <ul className="nav flex-column">
                            <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Home</a></li>
                            <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Features</a></li>
                            <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Pricing</a></li>
                            <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">FAQs</a></li>
                            <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">About</a></li>
                        </ul>
                    </Col>

                    <Col xs={12} sm={12} md={4} className='offset-md-1' >
                        <Form>
                            <h5>Subscribe to our newsletter</h5>
                            <p>Monthly digest of what's new and exciting from us.</p>
                            <div className="d-flex w-100 gap-2">
                                <label htmlFor="newsletter1" className="visually-hidden">Email address</label>
                                <input id="newsletter1" type="text" className="form-control" placeholder="Email address" />
                                <Button className="custom-btn" type="button">Subscribe</Button>
                            </div>
                        </Form>
                    </Col>
                </Row>

                <div className="d-flex justify-content-between py-4 my-4 border-top">
                    
                        <p>Â© 2024 WeClear, Inc. All rights reserved.</p>
                    
                        <ul className="list-unstyled d-flex ">
                            <li className="ms-3"><a className="link-dark" href="#"><FaTwitter /></a></li>
                            <li className="ms-3"><a className="link-dark" href="#"><FaInstagram /></a></li>
                            <li className="ms-3"><a className="link-dark" href="#"><FaFacebookSquare/></a></li>
                        </ul>
                    
                </div>

              
            </Container>
        </footer>
    );
}

export default Footer;