import React, { createContext, useEffect, useState } from 'react';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
    const [cart, setCart] = useState(() => {
        const storedCart = localStorage.getItem('cart');
        return storedCart ? JSON.parse(storedCart) : {};
    });

    useEffect(() => {
        localStorage.setItem('cart', JSON.stringify(cart));
    }, [cart]);

    const updateQuantity = (workId, name, price, quantity) => {
        
        if (quantity <= 0) {
            removeFromCart(workId);
        } else {
            setCart((prevCart) => ({
                ...prevCart,
                [workId]: { name, price, quantity }, // Consistent update for quantity
            }));
        }
    };
    
    const removeFromCart = (workId) => {
        setCart((prevCart) => {
            const newCart = { ...prevCart };
            delete newCart[workId];  // Remove based on workId to ensure deletion
            return newCart;
        });
    };

    const emptyCart = () => {
       
        setCart(() => {
            const newCart = {};
            return newCart;
        });
        console.log("empty", cart)
       
    };

    return (
        <CartContext.Provider value={{ cart, updateQuantity, removeFromCart, emptyCart }}>
            {children}
        </CartContext.Provider>
    );
};
