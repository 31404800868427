import React, { useContext, useState } from "react";
import "./styles/Cart.css"; // Ensure this CSS file has your custom styles
import { Button, Container, Row, Col, Accordion, Modal, Form, Card, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CartContext } from "./CartContext";
import { FaPlus } from "react-icons/fa";
import dayjs from "dayjs";

function Cart() {
  const { cart, removeFromCart, updateQuantity } = useContext(CartContext);

  const handleChange = (workId, work, newQuantity) => {
    console.log(`Changing quantity of ${work.name} to ${newQuantity}`);
    if (newQuantity < 1) {
      console.log(`Removing ${work.name} from cart`);
      removeFromCart(workId);
    } else {
      updateQuantity(workId, work.name, work.price, newQuantity);
    }
  };


  const [selectedAddress, setSelectedAddress] = useState(null);
  const [addresses, setAddresses] = useState([
    {
      id: 1,
      name: "Vinaya",
      address:
        "1275 E UNIVERSITY DR UNIT 205, TEMPE, AZ, 85288-5197, United States",
    },
    {
      id: 2,
      name: "Deepika",
      address:
        "1275 E University Dr, Unit 205, Tempe, Arizona, 85288, United States",
    },
    {
      id: 3,
      name: "Venu",
      address:
        "2255 W GERMANN RD APT 2083, CHANDLER, AZ, 85286-7276, United States",
    },
  ]);

  const [showModal, setShowModal] = useState(false); // Modal state
  const [newAddress, setNewAddress] = useState({
    name: "",
    street: "",
    city: "",
    state: "",
    zipcode: "",
  });

  const [activeKey, setActiveKey] = useState("1"); // State for accordion active key

  // Function to handle adding new address
  const handleAddAddress = () => {
    const newId = addresses.length + 1;
    const fullAddress = `${newAddress.street}, ${newAddress.city}, ${newAddress.state}, ${newAddress.zipcode}`;
    setAddresses([
      ...addresses,
      { id: newId, name: newAddress.name, address: fullAddress },
    ]);
    setShowModal(false); // Close modal after adding
    setSelectedAddress(newId);
  };

  const [selectedSlot, setSelectedSlot] = useState({
    date: dayjs(),
    time: "",
  });

  const timeSlots = [
    { start: "09:00", end: "10:00 " },
    { start: "10:00", end: "11:00 " },
    { start: "11:00", end: "12:00" },
    { start: "13:00", end: "14:00" },
    { start: "14:00", end: "15:00" },
    { start: "15:00", end: "16:00" },
    { start: "16:00", end: "17:00" },
    { start: "17:00", end: "18:00" },
  ];

  // Get the next 7 days
  const getNextSevenDays = () => {
    const days = [];
    for (let i = 0; i < 7; i++) {
      days.push(dayjs().add(i, "day"));
    }
    return days;
  };

  const nextSevenDays = getNextSevenDays();

  // Check if a time slot is past for today
  const isSlotPassed = (slot) => {
    if (selectedSlot["date"].isSame(dayjs(), "day")) {
      const currentTime = dayjs();

      const slotTime = dayjs()
        .hour(parseInt(slot.start.split(":")[0]))
        .minute(parseInt(slot.start.split(":")[1] || "0"))
        .second(0); // Reset seconds to 0 for accurate comparison

      return currentTime.isAfter(slotTime);
    }

    return false;
  };

  const handleSteps = () => {
    if (activeKey === "1" && selectedAddress) {
      // Expand the next accordion section (Slot section)
      setActiveKey("2");
      addresses.find((address) => address.id === selectedAddress);
    } else if (activeKey === "2" && selectedSlot) {
      setActiveKey("3");
    }
  };


  return (
    <>
      {Object.keys(cart).length === 0 ? (
        <Container className="cart-empty">
          <img src="/images/empty-cart.jpg" alt="Empty Cart" width="400px" />
          <h4>Oops!</h4>
          <h5>Your cart is empty...</h5>
          <Button variant="outline-primary" as={Link} to="/home">
            Explore Services
          </Button>
        </Container>
      ) : (
        <Container>
        <Row>
        <Col md={8}>
            <Accordion
              defaultActiveKey="1"
              activeKey={activeKey}
              onSelect={(key) => setActiveKey(key)}
            >
              <Accordion.Item eventKey="1">
                <Accordion.Header>1. Select your address</Accordion.Header>
                <Accordion.Body className="p-5">
                  <Form>
                    {addresses.map((address) => (
                      <Form.Check
                        key={address.id}
                        type="radio"
                        label={`${address.name} - ${address.address}`}
                        name="address"
                        value={address.id}
                        checked={selectedAddress === address.id}
                        onChange={() => setSelectedAddress(address.id)}
                      />
                    ))}
                  </Form>
                  <FaPlus />
                  <Button variant="link" onClick={() => setShowModal(true)}>
                    Add a new address
                  </Button>

                  <Button variant="warning" onClick={handleSteps}>
                    Use this address
                  </Button>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2">
                <Accordion.Header>2. Select your slot</Accordion.Header>
                <Accordion.Body>
                  <h5>Select a Date</h5>
                  <div className="horizontal-scroll">
                    {nextSevenDays.map((date, index) => (
                      <Button
                        key={index}
                        variant={
                          selectedSlot["date"].isSame(date, "day")
                            ? "primary"
                            : "outline-primary"
                        }
                        onClick={() =>
                          setSelectedSlot({ date: date, time: "" })
                        }
                        className="date-button"
                      >
                        {date.format("ddd, MMM D")}
                      </Button>
                    ))}
                  </div>

                  <h5 className="mt-4">Select a Time Slot</h5>
                  <Row>
                    {timeSlots.map((slot, index) => (
                      <Col key={index} sm={6} md={4} lg={3} className="mb-2">
                        <Button
                          variant={
                            selectedSlot["time"]?.start === slot.start
                              ? "primary"
                              : "outline-primary"
                          }
                          onClick={() =>
                            setSelectedSlot((prevState) => ({
                              ...prevState,
                              time: slot,
                            }))
                          }
                          disabled={isSlotPassed(slot)}
                          block
                        >
                          {slot.start} - {slot.end}
                        </Button>
                      </Col>
                    ))}
                  </Row>

                  <Button
                    className="mt-4"
                    variant="success"
                    disabled={!selectedSlot}
                    onClick={handleSteps}
                  >
                    Confirm Time Slot
                  </Button>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="3">
                <Accordion.Header>3. Select a payent method</Accordion.Header>
                <Accordion.Body>{/* Delivery and items info */}</Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
          <Col md={4}>
            <div className="cart-container">
              <ul className="cart-list">
                {Object.entries(cart).map(([workId, work]) => (
                  <li key={workId} className="cart-item">
                    <div className="cart-item-details">
                      <h6 className="cart-item-name">{work.name}</h6>
                      <div className="quantity-controls">
                        <button
                          className="quantity-btn"
                          onClick={() => handleChange(workId, work, work.quantity - 1)}
                        >
                          -
                        </button>
                        <span className="quantity">{work.quantity}</span>
                        <button
                          className="quantity-btn"
                          onClick={() => handleChange(workId, work, work.quantity + 1)}
                        >
                          +
                        </button>
                      </div>
                      <span className="cart-item-price">₹{work.price}</span>
                    </div>
                    <hr className="item-separator" />
                  </li>
                ))}
              </ul>
            </div>
            <Card className="mb-4 p-3 shadow-sm">
        <Card.Body>
          <h6>Payment summary</h6>
          <ListGroup variant="flush">
            <ListGroup.Item className="d-flex justify-content-between">
              <span>Item total</span>
              <span>₹3,199</span>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex justify-content-between">
              <span>Taxes and Fee</span>
              <span>₹349</span>
            </ListGroup.Item>
          </ListGroup>
        </Card.Body>
        <Card.Footer className="d-flex justify-content-between align-items-center">
          <h5>Amount to pay</h5>
          <div className="text-end">
            <h5>₹3,548</h5>
            <a href="#" className="text-muted">View breakup</a>
          </div>
        </Card.Footer>
      </Card>
          </Col>
        </Row>







         {/* Modal for Adding New Address */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Address</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                value={newAddress.name}
                onChange={(e) =>
                  setNewAddress({ ...newAddress, name: e.target.value })
                }
              />
            </Form.Group>

            <Form.Group controlId="street">
              <Form.Label>Street</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter street"
                value={newAddress.street}
                onChange={(e) =>
                  setNewAddress({ ...newAddress, street: e.target.value })
                }
              />
            </Form.Group>

            <Form.Group controlId="city">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter city"
                value={newAddress.city}
                onChange={(e) =>
                  setNewAddress({ ...newAddress, city: e.target.value })
                }
              />
            </Form.Group>

            <Form.Group controlId="state">
              <Form.Label>State</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter state"
                value={newAddress.state}
                onChange={(e) =>
                  setNewAddress({ ...newAddress, state: e.target.value })
                }
              />
            </Form.Group>

            <Form.Group controlId="zipcode">
              <Form.Label>Zip Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter zip code"
                value={newAddress.zipcode}
                onChange={(e) =>
                  setNewAddress({ ...newAddress, zipcode: e.target.value })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddAddress}>
            Save Address
          </Button>
        </Modal.Footer>
      </Modal>
        </Container>







      )}
    </>
  );
}

export default Cart;
