import React, { useContext, useEffect, useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import WorkCard from './WorkCard';
import { UserContext } from './UserContext';
import WorkModal from './WorkAdminModal';
import { useParams } from 'react-router-dom';
import './styles/WorksSubCatList.css';
import { CategoryContext } from './CategoryContext';

const WorksList = () => {
  const {category} = useContext(CategoryContext);
  const { sub_category } = useParams();
  const [works, setWorks] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const { user } = useContext(UserContext);


  const fetchServices = async () => {
    try {
      //api.weclear.in https://localhost:8080/api1/services/${category}/${sub_category}
      console.log(`http://localhost:8080/api1/services/${category}/${sub_category}`)
      const response = await fetch(` https://api.weclear.in/api1/services/${category}/${sub_category}`,{
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log(data)
      setWorks(data);
    } catch (error) {
      console.error('Error fetching services:', error);
    }
  };

  useEffect(() => {
    fetchServices();
  }, [category, sub_category]);

  const handleDelete = async (id) => {
    const response = await fetch(`https://api.weclear.in/api1/deleteservice/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });
    if (response.ok) {
      alert("Service deleted");
      fetchServices();
    } else {
      alert("Failed to delete service: ", response.statusText);
    }
  };

  const handleAddOrEditService = async (serviceData) => {
    console.log("came data", serviceData)
    if (selectedService) {
      await fetch(`https://api.weclear.in/api1/updateservice/${selectedService.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(serviceData),
      });
    } else {
      
      await fetch('https://api.weclear.in/api1/addservice', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(serviceData),
      });
    }
    fetchServices();
  };

  const openModalForAdd = () => {
    setSelectedService(null);
    setShowModal(true);
  };

  const openModalForEdit = (service) => {
    setSelectedService(service);
    setShowModal(true);
  };

  const groupWorksByGroup = (works) => {
    return works.reduce((acc, work) => {
      if (!acc[work.group]) {
        acc[work.group] = [];
      }
      acc[work.group].push(work);
      acc[work.group].sort((a, b) => a.price - b.price);   //sort according to price
      return acc;
    }, {});
  };

  const groupedWorks = groupWorksByGroup(works);

  return (
    <>
      <Container>
        

        
        {/* for admin */}
        {user && user.role === 'ADMIN' ? (
          <>
          <Row>
            <Col sm={8}>
              <h2 className="my-4">Services</h2>
            </Col>
            <Col sm={4}>
            <Button className="btn-primary" onClick={openModalForAdd}>
              Add a Service
            </Button>
            </Col>
          </Row>
            {Object.entries(groupedWorks).map(([group, works]) => (
              <div key={group} >
                <h2>{group}</h2>
                <Row>
                  {works.map(work => (
                    <Col key={work.id} xs={12} sm={6} md={4}>
                      <div className="work-card">
                        <WorkCard work={work} handleDelete={handleDelete} openEditModal={openModalForEdit} />
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            ))}
          </>
        ) :
                  (



        
          <Row>
            <h2 className="my-4">Services</h2>
              
                
                {Object.entries(groupedWorks).map(([group, works]) => (
                  
                    <Col key={group} xs={12} sm={6} md={4}>
                     
                      <WorkCard key={group} work={works[0]} worksGroup={works} />
                      
                    </Col>

                    
                  ))}
                  
               
          </Row>

        
                  )}
        

      </Container>

      <WorkModal
        show={showModal}
        onHide={() => setShowModal(false)}
        sub_category={sub_category}
        selectedService={selectedService}
        handleSubmit={handleAddOrEditService}
      />
    </>
  );
};

export default WorksList;
